import affordable_icon from '../../assets/icons/benefits/affordable_icon.svg'
import energy_icon from '../../assets/icons/benefits/energy_icon.svg'
import farm_icon from '../../assets/icons/benefits/farm_icon.svg'
import cloud_icon from '../../assets/icons/benefits/icon_cloud.svg'
import lego_icon from '../../assets/icons/benefits/Lego_icon.svg'
import mixedFruit_icon from '../../assets/icons/benefits/mixedFruit_icon.svg'

import PictureColdRoom from '../../assets/images/products/kakuzi/coldroom_3.jpeg';
import PicturePackHouse from '../../assets/images/products/lauetta/packhouse_worker.jpg';
import PictureCloud from '../../assets/images/products/cloud/001.jpg';


export const benefits = [
    {
        title: "On-Farm",
        content: "On-farm pre-cooling leads to more shelf-life, less claims and potentially choosing the most efficient freight option. Up to 2 USD/kg savings if you shift from air to sea.",
        icon: farm_icon,
    },
    {
        title: "Modular",
        content: "We offer a range of, easy to install, solutions responding to your needs and can grow with your business. ",
        // content: "BRC and ISO22000 compliant prefabricated and easy to install. We offer a range of solutions responding to your needs and can grow with your business. ",
        icon: lego_icon,
    },
    {
        title: "Efficient",
        content: "High quality panels coupled with high efficiency machines and electronics allow for up to 25% energy savings on top line competition",
        icon: energy_icon,
    },
    // {
    //     title: "Remote Monitoring",
    //     content: "You can monitor the performance of your facility from your mobile phone and reduce stop down.",
    //     icon: cloud_icon,
    // },
    // {
    //     title: "Product Quality",
    //     content: "Bring the cold chain on-farm and save up to 20% by increasing quality and reducing losses. ",
    //     icon: mixedFruit_icon,
    // },
    // {
    //     title: "Affordable",
    //     content: "we provide 3 to 5 years asset finance without additional collateral affecting your cash flow. ",
    //     icon: affordable_icon,
    // },
]

export const packhouse = {
    title: "Packhouses",
    content: "InspiraFarms coldroom design ensures best in class workflows and compliance with international food safety standards, making it easier for agribusinesses to comply with the demands of higher value markets and supply chains",
    features: [
        "HACCP-Compliant Facility",
        "administrative and QA areas",
        "cold rooms and precooling solutions",
        "handling space and loading docks",
        "Lightweight & Easily Expandable",
        "processing space hygiene area",
        "Financing Available"
    ],
    pictures: [
        PicturePackHouse
    ]
} 

export const coldroom = {
    title: "Coldrooms",
    content: "Our cold room solutions range from 25m2 rooms for decentralized operations to large-scale pre-cooling and cold room solutions. They are designed for tropical and remote areas.",
    features: [
        "HACCP-Compliant Facility",
        "Up to 2000m2",
        "Remote Monitoring & Control",
        "Energy-Efficient Cooling",
        "Lightweight & Easily Expandable",
        "Adjustable to a multitude of crops",
        "Financing Available"
    ],
    pictures: [
        PictureColdRoom
    ]
} 

export const cloud = {
    title: "InspiraFarms Cloud",
    content: "All InspiraFarms products are connected to our remote monitoring cloud that enables you to check on your produce and processes from a distance",
    features: [
        "Desktop & Mobile Friendly",
        "UContinuous Tracking of Quality Indicators",
        "Remote Monitoring & Control",
        "No software installation required",
        "Available with all InspiraFarms facilities",
        "Adjustable to individual needs",
        "Remote supervision of critical machine settings"
    ],
    pictures: [
        PictureCloud
    ]
} 