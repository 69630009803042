import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Card, Typography } from '@material-ui/core';
 
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: 220
  },
  iconBox: {
    background: theme.palette.neutral.main,
    borderRadius: "100%",
    marginBottom: 20
  }
}))


const Benefit = ({ title, content, children }) => {
  const classes = useStyles()

  return (
      <div className={classes.root}>
        <div className={classes.iconBox}>
          {children}
        </div>
        <Typography variant="h5" paragraph={true} align="center"> {title} </Typography>
        <Typography variant="body1" paragraph={true} align="center"> {content} </Typography>
      </div>
  );
};

Benefit.propTypes = {

};

Benefit.defaultProps = {

};

export default Benefit;
