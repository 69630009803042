import React from "react"
import { useRecoilState } from "recoil"
import { worldState } from "../store"
import Layout from '../layouts/main'
import SEO from "../components/seo"
import { useTheme } from "@material-ui/core/styles";
import { Grid, Hidden, makeStyles, Container, CardMedia, Card, Box, CardActions , CardContent, Typography } from '@material-ui/core';
import CircleElement from '../components/shared/CircleElement'
import { Link, Button } from "gatsby-theme-material-ui";
import { Image } from 'gatsby';
import WaveElement from '../components/shared/WaveElement'
import BenefitsCard from '../components/landingPage/BenefitsCard'
import Configurator from '../components/Configurator'
import FirstConfiguratorCard from '../components/landingPage/FirstConfiguratorCard'
import ProductCard from '../components/landingPage/ProductCard'
import {coldroom, packhouse, cloud} from '../components/landingPage/content'
import happyWorkerPicture from '../assets/images/products/happy_worker_blueberry_PS.jpg'
import CookieConsent from "react-cookie-consent";

import "./index.sass"

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(3),
  },
  subTitle: {
    marginBottom: theme.spacing(4),
  },
  button: {
    marginRight: theme.spacing(1),
  },
  fullHeightSection: {
      minHeight: '100vh',
      marginTop: '10vh'
  },
  firstSection: {
    minHeight: '60vh',
    marginTop: '10vh',
    [theme.breakpoints.down('xs')]: {
      minHeight: '50vh',
    },
  },
  variableSection: {
    marginTop: '20vh',
    paddingBottom: '20vh'
  },
  backgroundLayer: {
    position: 'absolute',
    top: 0,
    zIndex: -1,
    height: '100%',
    width: '100%'
  },
  sectionBackgroundLayer: {
    position: 'relative',
    top: 0,
    zIndex: -1,
    height: '100%',
    width: '100%'

  },
  mutedBackground: {
    backgroundColor: theme.palette.neutral.main
  },
  circleImageWrapper: {
    width: 400,
    height: 400,
    // minWidth: 300,
    // minHeight: 300,
    marginBottom: "80px",
    overflow: "hidden",
    borderRadius: "100%",
    backgroundSize: "cover",                  /* <------ */
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
  }
}));


const Home = ({location}) => {
     // Hook here

  const theme = useTheme();

  const classes = useStyles()

  return (
      <Layout location={location}>
        <section className={classes.firstSection}>
          <Container maxWidth="md" height="100%">
            <Grid container alignItems="center" alignContent="center">
              <Grid item md={8}>
                  <Typography variant="h1" className={classes.title}>
                  Improve shelf-life with our smart cooling solutions
                  </Typography>
                  <Typography variant="h2" mb="1" className={classes.subTitle}>
                  Best on-farm cold rooms, precooling and packhouses for fruit and vegetable in Africa
                  </Typography>
                  <Button color="primary" variant="contained" to={'/configurator' + location.search} className={classes.button}>Start Configuration</Button>
                  {/* <Button color="primary" variant="outlined">Learn More</Button> */}
              </Grid>
              <Grid item md={4}>
              <Hidden smDown>
                <div className={classes.circleImageWrapper} style={{backgroundImage: `url(${happyWorkerPicture})`}}></div>
              </Hidden>
              </Grid>
            </Grid>
          </Container>
          <div className={classes.backgroundLayer}>
            <CircleElement color={theme.palette.tertiary.main} top={"-18vw"} right={"-20vw"} size={"50vw"} zIndex={-1}></CircleElement>
            <CircleElement color={theme.palette.secondary.main} top={"-20vw"} right={"1vw"} size={"50vw"} zIndex={-2}></CircleElement>
            <CircleElement color={theme.palette.quaternary.main} top={"8vw"} right={"-25vw"} size={"50vw"} zIndex={-3}></CircleElement>
          </div>
        </section>
        <section className={`${classes.variableSection} ${classes.mutedBackground}`}>
          <div className={classes.sectionBackgroundLayer}>
            <WaveElement top={"-20vw"}/>
          </div>
          <Container maxWidth="md" height="100%">
            <BenefitsCard title="Benefits"></BenefitsCard>
          </Container>
        </section>
        <section className={`classes.variableSection`}>
        <div className={`${classes.sectionBackgroundLayer} ${classes.mutedBackground}`}>
            <WaveElement color="secondary" backgroundColor={theme.palette.neutral.main}/>
          </div>
          <Container maxWidth="md" height="100%">
            <FirstConfiguratorCard/>
          </Container>
        </section>
        <section className={classes.fullHeightSection}>
        <div className={classes.sectionBackgroundLayer}>
            <CircleElement color={theme.palette.tertiary.main} bottom={"-30vw"} right={"0vw"} size={"20vw"} zIndex={-1}></CircleElement>
            <CircleElement color={theme.palette.secondary.main} bottom={"-38vw"} right={"3vw"} size={"5vw"} zIndex={-1}></CircleElement>

          </div>
          <Container maxWidth="md" height="100%">
            <ProductCard configure="/configurator" title={coldroom.title} content={coldroom.content} features={coldroom.features} picture={coldroom.pictures[0]}></ProductCard>
            <ProductCard configure="/configurator" title={packhouse.title} content={packhouse.content} features={packhouse.features} picture={packhouse.pictures[0]}></ProductCard>
            <ProductCard title={cloud.title} content={cloud.content} features={cloud.features} picture={cloud.pictures[0]}></ProductCard>
          </Container>
          <div className={classes.sectionBackgroundLayer}>
            <CircleElement color={theme.palette.primary.main} bottom={"20vw"} left={"0vw"} size={"20vw"} zIndex={-1}></CircleElement>
            <CircleElement color={theme.palette.tertiary.main} bottom={"18vw"} left={"3vw"} size={"5vw"} zIndex={-1}></CircleElement>
          </div>
        </section>
        <section className={classes.variableSection}>
          <div className={classes.sectionBackgroundLayer}>
              <WaveElement color="secondary"/>
            </div>
            <Container maxWidth="md" height="100%">
              <FirstConfiguratorCard/>
            </Container>
        </section>
        <CookieConsent
  location="bottom"
  buttonText="Accept"
  cookieName="inspiraFarmsConsentCookie"
  style={{ background: "#2B373B" }}
  buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
  expires={150}
>
  This website uses cookies to enhance the user experience.{" "}
  {/* <span style={{ fontSize: "10px" }}>This bit of text is smaller :O</span> */}
</CookieConsent>
      </Layout>
  )
}

export default Home
