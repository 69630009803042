import React from 'react';
import PropTypes from 'prop-types';
import Wave from '../../assets/illustrativeElements/WaveElement.svg'
import { useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    width: '110vw',
    height: 'auto',
    [theme.breakpoints.down('xs')]: {
      width: '400vw',
    },
  },
  wave: {
    width: '100%',
    height: '100%',
    '& path': {
        fill: '#F9F9F9'
      }
    },
    primary: {
      '& path': {
          fill: theme.palette.primary.main 
        }
      },
      secondary: {
        '& path': {
            fill: theme.palette.secondary.main 
          }
        }
}))


const WaveElement = ({ size, top, right, left, bottom, zIndex, color, backgroundColor }) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.up('xs'));
  const classes = useStyles()
  console.log("MOBILE?", mobile)
  return (
    <div className={classes.root} style={{width: size , height: size, top, right, left, bottom, zIndex, backgroundColor}}>
      <Wave className={`${classes.wave} ${color === 'secondary' ? classes.secondary : null} ${color === 'primary' && classes.primary}`}></Wave>
    </div>
  );
};

WaveElement.propTypes = {
    color: PropTypes.string,
    size: PropTypes.number,
};

WaveElement.defaultProps = {
};

export default WaveElement;
