import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Card, Grid, Typography, CardActions, CardMedia } from '@material-ui/core';
import SectionTitle from './../shared/SectionTitle'
import ListDot from './../shared/ListDot'
import { Link, Button } from "gatsby-theme-material-ui";
 
const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        minHeight: 200,
        padding: theme.spacing(3),
        marginBottom: theme.spacing(9),
      },
      gridItem: {
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "center"
      },
      content: {
        maxWidth: 300,
        paddingBottom: theme.spacing(3),
        [theme.breakpoints.down('xs')]: {
          paddingTop: theme.spacing(3),
        },
      },
      action: {
        paddingTop: theme.spacing(4)
      },
      feature: {
        fontWeight: "500"
      },
      icon: {
        marginRight: theme.spacing(1),
      },
      gridContainer: {
          flexWrap: 'wrap-reverse'
      },
      cover: {
        height: "100%",
        minHeight: "200px",
        margin: "0 auto",
        minWidth: '100%',
        borderRadius: "15px",
    },
    coverWrapper: {
        width: "100%"
    }
}))


const ProductCard = ({ title, content, features, picture, link, configure }) => {
  const classes = useStyles()

  return (
      <Card className={classes.root}>
        <SectionTitle> {title} </SectionTitle>
        <Grid container className={classes.gridContainer}>
            <Grid item sm={6}>
                <Typography className={classes.content} variant="body1" paragraph={true} align="left">
                    {content}
                </Typography>
                { features.map( feature => (
                    <Typography component={'div'} className={classes.feature} key={`KEY_${feature}`}> 
                        {/* <AddIcon color="secondary" className={classes.icon}/> */}
                        <ListDot></ListDot>
                        {feature}
                    </Typography>
                ))}

                <CardActions className={classes.action}>
                            {configure && 
                                <Button variant="contained" color="primary" to={configure}>
                                    Configure!
                                </Button>
                            }
                            {link && 
                                <a style={{textDecoration: "none"}} href={link} target="_blank">
                                    <Button variant="outlined" color="primary">
                                        Learn More
                                    </Button>
                                </a>
                            }
                </CardActions>
            </Grid>
            <Grid item sm={6} className={classes.coverWrapper}>
                <CardMedia
                            className={classes.cover}
                            image={picture}
                            title={title}
                />
            </Grid>
        </Grid>
      </Card>
  );
};

ProductCard.propTypes = {

};

ProductCard.defaultProps = {

};

export default ProductCard;
