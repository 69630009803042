import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Card, Grid } from '@material-ui/core';
import { benefits } from './content'
import Benefit from './Benefit'
import SectionTitle from './../shared/SectionTitle'
 
const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        minHeight: 200,
        padding: theme.spacing(3)
      },
      gridItem: {
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "center"
      }
}))


const BenefitsCard = ({ title }) => {
  const classes = useStyles()

  return (
      <Card className={classes.root}>
        <SectionTitle> {title} </SectionTitle>
        <Grid container>
        { benefits.map( benefit => <Grid key={`key_grid_${benefit.title}`}  className={classes.gridItem} item sm={4} xs={12}>
          <Benefit key={`key_${benefit.title}`} title={benefit.title} content={benefit.content} icon={benefit.icon}><benefit.icon></benefit.icon></Benefit>
          </Grid>)}
        </Grid>
      </Card>
  );
};

BenefitsCard.propTypes = {

};

BenefitsCard.defaultProps = {

};

export default BenefitsCard;
