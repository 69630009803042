import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from '@material-ui/core';
// import { useSpring, animated } from 'react-spring'


import { makeStyles, useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  circle: {
    borderRadius: '100%',
    position: 'absolute',
  },
}))


const CircleElement = ({ color, size, top, right, left, bottom, zIndex, mobileSize, mobileTop, mobileRight, mobileLeft, mobileBottom }) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.up('xs'));

  const classes = useStyles()

  // const props = useSpring({
  //   from: { background: color, width: size, height: size, top, right, left, bottom, zIndex },
  //   to: async next => {
  //     while (1) {
  //       await next({ left: '0%', top: '0%', width: '100%', height: '100%', background: 'lightblue' })
  //       await next({ height: '50%', background: 'lightgreen' })
  //       await next({ width: '50%', left: '50%', background: 'lightgoldenrodyellow' })
  //       await next({ top: '0%', height: '100%', background: 'lightpink' })
  //       await next({ top: '50%', height: '50%', background: 'lightsalmon' })
  //       await next({ width: '100%', left: '0%', background: 'lightcoral' })
  //       await next({ width: '50%', background: 'lightseagreen' })
  //       await next({ top: '0%', height: '100%', background: 'lightskyblue' })
  //       await next({ width: '100%', background: 'lightslategrey' })
  //     }
  //   },
  // })

  return (
    <div className={classes.circle} style={{background: color, width: size, height: size, top, right, left, bottom, zIndex}}>
    </div>
  );
};

CircleElement.propTypes = {
    color: PropTypes.string,
    size: PropTypes.number,
};

CircleElement.defaultProps = {
    color: '#FFB500',
    size: 200,
};

export default CircleElement;
