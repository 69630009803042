import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "gatsby-theme-material-ui";
import { makeStyles } from '@material-ui/core/styles';
import LogoSVG from '../../assets/logo.svg';
import Arrow from '../../assets/icons/arrow-right.svg';

const useStyles = makeStyles((theme) => ({
  dot: {
    marginRight: 10,
    marginTop: 5,
    width: 10,
    height: 10,
    minHeight: 10,
    minWidth: 10,
    borderRadius: "100%",
    background: theme.palette.secondary.main,
    display: "inline-block"
  }
}))


const ListDot = ({}) => {
  const classes = useStyles()

  return (
    <div className={classes.dot}> </div>
  );
};

// ListDot.propTypes = {
// };

// NavLogo.defaultProps = {
// };

export default ListDot;
