import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Card, Grid, Typography } from '@material-ui/core';
 
const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: theme.spacing(3)
      },
      underline: {
        borderBottom: `2px solid ${theme.palette.secondary.main}`,
        // borderColor: theme.palette.secondary.main
      }
}))


const SectionTitle = ({ children }) => {
  const classes = useStyles()

  return (
    <Typography variant="h5" className={classes.root}>
        <span className={classes.underline}>{children}</span>
    </Typography>
  );
};

SectionTitle.propTypes = {

};

SectionTitle.defaultProps = {

};

export default SectionTitle;
