import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ConfiguratorCard from '../../components/Configurator/ConfiguratorCard'
import { CardActions, CardContent, CardHeader } from '@material-ui/core';
import { Button } from 'gatsby-theme-material-ui';

 
const useStyles = makeStyles((theme) => ({
  header: {
    maxWidth: 300,
    fontFamily: "Josefin Sans",
    textAlign: "center"
},
    root: {
        width: "100%",
        minHeight: 200,
        padding: theme.spacing(3)
      },
      button: {
        marginTop: 'auto',
        justifyContent: "space-around",
        width: "100%"
    },
}))


const FirstConfiguratorCard = ({ title }) => {
  const classes = useStyles()

  return (
    <ConfiguratorCard small>
      <CardHeader
                className={classes.header}
                title={"Find out which InspiraFarms solution fits you best!"}
                subheader={"In Five Simple Steps"}
            />
            <CardActions className={classes.button}>
                    <Button variant="contained" color="primary" to="/configurator"> Start Configuration</Button>
            </CardActions>

    </ConfiguratorCard>
  );
};

FirstConfiguratorCard.propTypes = {

};

FirstConfiguratorCard.defaultProps = {

};

export default FirstConfiguratorCard;
